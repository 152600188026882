import React, { useState } from 'react';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { checkPermission } from '../utils';

// utils
import permissions from '../utils/permissionConfig';
import { GetUserPermissions } from '../contexts/permission-context';

const SideBar = () => {
  const { permissions: userPermissions } = GetUserPermissions();
  const [isShowMobile, toggleShow] = useState(false);

  return (
    <div className={`flex flex-col pt-6 pr-12 items-end ${isShowMobile ? 'z-10 fixed' : ''} lg:static bg-white h-full lg:z-0`}>
      <div className="lg:hidden">
        {isShowMobile ? <CloseOutlined className="mr-3" onClick={() => toggleShow(false)} />
          : <MenuOutlined onClick={() => toggleShow(true)} />}
      </div>
      <Menu
        className={`h-full ${isShowMobile ? '' : 'hidden'} lg:block border-r-0`}
        mode="inline"
        selectedKeys={[window.location.pathname.split('/')[1]]}
        defaultSelectedKeys={['spaces']}
      >
        {
          checkPermission(permissions.visits.permission, userPermissions, false) ?
            <Menu.Item key="visits">
              <NavLink to="/visits">
                <div>
                  <span className="text-body-bold">Visits</span>
                </div>
              </NavLink>
            </Menu.Item>
            : null
        }
        {
          checkPermission(permissions.spaces.permission, userPermissions, false) ?
            <Menu.Item key="spaces">
              <NavLink to="/spaces">
                <div>
                  <span className="text-body-bold">Spaces</span>
                </div>
              </NavLink>
            </Menu.Item>
            : null
        }
        {
          checkPermission(permissions.locker_types.permission, userPermissions, false) ?
            <Menu.Item key="locker-types">
              <NavLink to="/locker-types">
                <div>
                  <span className="text-body-bold">Locker Types</span>
                </div>
              </NavLink>
            </Menu.Item>
            : null
        }
        {
          checkPermission(permissions.customers.permission, userPermissions, false) ?

            <Menu.Item key="customers">
              <NavLink to="/customers">
                <div className="flex space-x-2">
                  <span className="text-body-bold">Customers</span>
                </div>
              </NavLink>
            </Menu.Item>
            : null
        }
        {
          checkPermission(permissions.visits.permission, userPermissions, false) ?
            <Menu.Item key="doorstep-lockers">
              <NavLink to="/doorstep-lockers">
                <div className="flex space-x-2">
                  <span className="text-body-bold">Doorstep Lockers</span>
                </div>
              </NavLink>
            </Menu.Item> : null
        }
        {/* <Menu.Item key="locker-managers">
          <NavLink to="/locker-managers">
            <div>
              <span className="text-body-bold">Locker Managers</span>
            </div>
          </NavLink>
        </Menu.Item> */}
        {/* <Menu.Item key="digital-certificates">
          <NavLink to="/digital-certificates">
            <div>
              <span className="text-body-bold">Digital Certificates</span>
            </div>
          </NavLink>
        </Menu.Item> */}
      </Menu>
    </div>
  );
};

export default SideBar;
