import React, { useEffect, useState } from 'react';
import NotificationBar from './components/Notifications/NotificationBar';
import AuthenticatedRoutes from './routes';
import UnAuthenticated from './routes/unAuthenticated';
import { Spin } from "antd";
import AuthUtils from './utils/AuthUtils';
import { LoadingOutlined } from '@ant-design/icons';
import AuthApi from './utils/ApiUtils/AuthApi';
import { PermissionsProvider } from './contexts/permission-context';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function App() {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!isAuthenticated && !!AuthUtils.getToken()) {
      AuthApi.verifyToken().then(() => {
        setAuthenticated(true);
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  return (
		<>
			<PermissionsProvider>
				<Spin indicator={antIcon} spinning={isLoading || false}>
					<NotificationBar />
					<div className='App'>
						{isAuthenticated ? (
							<AuthenticatedRoutes setAuthenticated={setAuthenticated} />
						) : (
							<UnAuthenticated setAuthenticated={setAuthenticated} />
						)}
					</div>
				</Spin>
			</PermissionsProvider>
		</>
	);
}

export default (App);
