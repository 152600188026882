import React from 'react';
import {
  Redirect, Route, BrowserRouter as Router, Switch,
} from 'react-router-dom';
import Spaces from '../pages/Spaces';
import CreateSpace from '../pages/Spaces/createSpace';
import Auth from './Auth';
import SpaceDetails from '../pages/Spaces/details';
import LockertypeDetails from '../pages/Spaces/lockerTypeDetails';
import LockerTypePricing from '../pages/Spaces/lockerTypePricing';
import NotFound from '../pages/NotFound';
import Nav from '../components/Nav';
import AddLocker from '../pages/Spaces/addLocker';
import LockerTypes from '../pages/LockerTypes';
import Customers from '../pages/Customers';
import CustomerDetails from '../pages/Customers/details';
import Visits from '../pages/Visits';
import CreateLockerVisit from '../pages/Visits/CreateVisit/CreateLockerVisit';
import CreateDoorstepVisit from '../pages/Visits/CreateVisit/CreateDoorstepVisit';
import StartVisit from '../pages/Visits/StartVisit';
import DoorstepLockers from '../pages/DoorstepLockers';
import LockerVisitDetails from '../pages/Visits/VisitDetails/LockerVisitDetails';
import DoorstepVisitDetails from '../pages/Visits/VisitDetails/DoorstepVisitDetails';
import permissions from '../utils/permissionConfig';
import { fetchPermittedRoute } from '../utils';
import { GetUserPermissions } from '../contexts/permission-context';
// import LockerManagerDetails from '../pages/locker-managers/details';
// import CreateLockerManager from '../pages/locker-managers/create';
// import DigitalCertificates from '../pages/digital-certificates';
// import DigitalCertificateDetails from '../pages/digital-certificates/details';

function Routes({ setAuthenticated }) {
  return (
    <div className="my-1 mx-auto">
      <div>
        <div className="flex flex-col">
          <Router>
            <Nav setAuthenticated={setAuthenticated} />
            <Switch>
              <Route
                path="/"
                exact
                component={() => <Redirect to={fetchPermittedRoute(GetUserPermissions().permissions)} />}
              />
              <Auth
                path="/spaces"
                exact
                component={Spaces}
                requiredPermissions={permissions.spaces.permission}
              />
              <Auth
                path="/spaces/create"
                exact
                component={CreateSpace}
                requiredPermissions={permissions.spaces.create_new_space.permission}

              />
              <Auth
                path="/spaces/details/:id"
                exact
                component={SpaceDetails}
                requiredPermissions={permissions.spaces.permission}
              />
              <Auth
                path="/spaces/:spaceId/locker-types/:lockerTypeId/details"
                exact
                component={LockertypeDetails}
                requiredPermissions={permissions.spaces.permission}
              />
              <Auth
                path="/spaces/:id/lockers/create"
                exact
                component={AddLocker}
                requiredPermissions={permissions.spaces.permission}
              />
              <Auth
                path="/spaces/:spaceId/locker-types/:lockerTypeId/pricing"
                exact
                component={LockerTypePricing}
                requiredPermissions={permissions.spaces.permission}
              />
              <Auth
                path="/locker-types"
                exact
                component={LockerTypes}
                requiredPermissions={permissions.locker_types.permission}
              />
              <Auth
                path="/customers"
                exact
                component={Customers}
                requiredPermissions={permissions.customers.permission}
              />
              <Auth
                path="/customers/details/:userId"
                exact
                component={CustomerDetails}
                requiredPermissions={permissions.customers.permission}
              />
              <Auth
                path="/visits"
                exact
                component={Visits}
                requiredPermissions={permissions.visits.permission}
              />
              <Auth
                path="/visits/create-locker-visit"
                exact
                component={CreateLockerVisit}
                requiredPermissions={permissions.visits.create_locker_visit.permission}
              />
              <Auth
                path="/visits/create-doorstep-visit"
                exact
                component={CreateDoorstepVisit}
                requiredPermissions={permissions.visits.create_doorstep_visit.permission}
              />
              <Auth
                path="/visits/start/:id"
                exact
                component={StartVisit}
                requiredPermissions={permissions.visits.permission}
              />
              <Auth
                path="/visits/locker-visit-details/:id"
                exact
                component={LockerVisitDetails}
                requiredPermissions={permissions.visits.permission}
              />
              <Auth
                path="/visits/doorstep-visit-details/:id"
                exact
                component={DoorstepVisitDetails}
                requiredPermissions={permissions.visits.permission}
              />
              <Auth
                path="/doorstep-lockers"
                exact
                component={DoorstepLockers}
                requiredPermissions={permissions.visits.permission}
              />
              {/* <Auth
                path="/locker-managers"
                exact
                component={LockerManagers}
              /> */}
              {/* <Auth
                path="/locker-managers/details"
                exact
                component={LockerManagerDetails}
              /> */}
              {/* <Auth
                path="/locker-managers/create"
                exact
                component={CreateLockerManager}
              /> */}
              {/* <Auth
                path="/digital-certificates"
                exact
                component={DigitalCertificates}
              />
              <Auth
                path="/digital-certificates/details"
                exact
                component={DigitalCertificateDetails}
              /> */}
              <Auth path="*" component={NotFound} />
            </Switch>
          </Router>
        </div>
      </div>
    </div>
  );
}

export default Routes;