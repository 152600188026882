import { DatePicker, TimePicker } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import Link from "../../../../components/Link";
import Button from "../../../../components/Buttons/Button";
import PrimaryCard from "../../../../components/Cards/PrimaryCard";
import Form from "../../../../components/Form/Form";
import Input from "../../../../components/Form/Input";
import LabelInput from "../../../../components/Form/LabelInput";
import HasuraApi from "../../../../utils/ApiUtils/HasuraApi";
import Loading from "../../../../components/loading";
import Api from "../../../../utils/ApiUtils/Api";
import CheckBox from "../../../../components/Checkbox/Checkbox";
import Notification from "../../../../components/Notifications";

export default function CreateVisit({ history }) {
  const [state, setStateHelper] = useState({
    isLoading: true,
    isError: false,
    isSubmitting: false,
    isSearching: false,
    spaces: [],
    formError: "",
    customerType: "new"
  });

  const setState = (changes) => {
    setStateHelper((prevState) => ({
      ...prevState,
      ...changes,
    }));
  };

  const getSpaceList = () => {
    setState({ isLoading: true, isError: false });
    Api.get("/space-config/space")
      .then((data) =>
        setState({ isLoading: false, spaces: data?.spaces?.rows })
      )
      .catch(() => setState({ isLoading: false, isError: true }));
  };

  useEffect(() => {
    getSpaceList();
  }, []);

  const getLockers = (id) => {
    setState({ isSearching: true });
    Api.get(`user/safe-user/${id}/lockers`)
      .then((data) => {
        const lockerData = data?.lockers;
        Object.keys(lockerData)?.forEach((key) => {
          lockerData[key] = lockerData[key]?.map((locker) => ({
            isSelected: true,
            ...locker,
          }));
        });
        setState({ lockers: lockerData, isSearching: false });
      })
      .catch(() => setState({ isSearching: false, isError: true }));
  };

  const searchUsingMobile = () => {
    setState({ isSearching: true, isError: false });
    Api.get(`user/safe-user?mobile_number=${state.mobile}`)
      .then((data) => {
        const visitors = [];
        if (data?.safeUser?.primary_customer) {
          visitors.push({
            type: "primary",
            name: data?.safeUser?.primaryUser?.name,
            firstName: data?.safeUser?.primaryUser?.first_name,
            lastName: data?.safeUser?.primaryUser?.last_name,
            id: data?.safeUser?.primary_customer,
          });
        }
        if (data?.safeUser?.secondary_customer) {
          visitors.push({
            type: "secondary",
            name: data?.safeUser?.secondaryUser?.name,
            firstName: data?.safeUser?.primaryUser?.first_name,
            lastName: data?.safeUser?.primaryUser?.last_name,
            id: data?.safeUser?.secondary_customer,
          });
        }
        if (data?.safeUser?.tertiary_customer) {
          visitors.push({
            type: "tertiary",
            name: data?.safeUser?.tertiaryUser?.name,
            firstName: data?.safeUser?.primaryUser?.first_name,
            lastName: data?.safeUser?.primaryUser?.last_name,
            id: data?.safeUser?.tertiary_customer,
          });
        }
        getLockers(data?.safeUser?.primary_customer);
        setState({
          name: data?.safeUser?.primaryUser?.name,
          firstName: data?.safeUser?.primaryUser?.first_name,
          lastName: data?.safeUser?.primaryUser?.last_name,
          visitors,
          primaryUserId: data?.safeUser?.primary_customer,
          isSearching: false,
        });
      })
      .catch(() => {
        setState({
          name: null,
          isSearching: false,
          isError: true,
        });
      });
  };
  const onChange = (type, e) => {
    switch (type) {
    case "mobile":
      setState({ mobile: e });
      break;
    case "firstName":
      setState({ firstName: e.target.value });
      break;
    case "lastName":
      setState({ lastName: e.target.value });
      break;
    case "visitor":
      setState({ visitor: e.target.value });
      break;
    case "date":
      setState({ date: e });
      break;
    case "time":
      setState({ time: e });
      break;
    case "spaceID":
      setState({ spaceID: e.target.value });
      break;
    case "visitType":
      setState({ visitType: e.target.value });
      break;
    default:
    }
  };

  const validation = () => {
    if (!state.date || !state.time) {
      setState({ formError: "Please Check all the fields" });
      return false;
    }
    return true;
  };
  const getSelectedLockerIds = () => {
    const { lockers } = state;
    const selectedLockers = [];
    Object.keys(lockers)?.forEach((key) => {
      lockers[key]?.forEach((locker) => {
        if (locker?.isSelected) selectedLockers.push(parseInt(locker?.id, 10));
      });
    });
    return selectedLockers;
  };

  const createExistingCustomerVisit = () => {
    if (getSelectedLockerIds()?.length <= 0) {
      setState({ customerType: 'new', visitType: '' });
      Notification.create("error", "Customer has no active physical locker Please create a new customer visit!");
      return;
    }
    const { visitor, date, time, visitType, spaceID, primaryUserId } = state;
    const visitDate = moment(date).format("DD/MM/YYYY");
    const visitTime = moment(time).format("HH:mm");
    const visitDateTime = moment(
      `${visitDate} ${visitTime}`,
      "DD/MM/YYYY HH:mm"
    );
    setState({ isSubmitting: true, isError: false });
    Api.post("/visit/create", {
      input: {
        visitor_id: parseInt(visitor, 10),
        space_id: parseInt(spaceID, 10),
        visit_type: visitType,
        visit_time: visitDateTime,
        primary_user_id: parseInt(primaryUserId, 10),
        locker_ids: getSelectedLockerIds(),
      },
    })
      .then(() => {
        setState({ isSubmitting: false });
        Notification.create("success", "Visit Created Successfully");
        history.push("/visits");
      })
      .catch(() => {
        setState({ isSubmitting: false, isError: true });
      });
  };
  const handleSubmit = () => {
    const {
      firstName,
      lastName,
      mobile,
      customerType,
      date,
      time,
      visitType,
      spaceID,
      primaryUserId,
    } = state;
    if (customerType === "new") {
      const visitDate = moment(date).format("DD/MM/YYYY");
      const visitTime = moment(time).format("HH:mm");
      const visitDateTime = moment(
        `${visitDate} ${visitTime}`,
        "DD/MM/YYYY HH:mm"
      );
      const createVisitQuery = {
        variables: {
          first_name: firstName,
          last_name: lastName,
          visit_time: visitDateTime,
          space_id: parseInt(spaceID, 10),
          mobile_number: `+91${mobile}`,
          visit_status: "VISIT_REQUESTED",
          visit_type: visitType,
          visit_loan_type: "",
          primary_user_id: parseInt(primaryUserId, 10),
          lat: 0,
          long: 0,
          address: {},
        },
        query: `
        mutation MyMutation(
          $visit_time: timestamptz,
          $visit_status: visit_status_enum,
          $first_name: String,
          $last_name: String,
          $mobile_number: String,
          $visit_loan_type: String,
          $visit_type: String,
          $space_id:Int,
          $primary_user_id: Int,
          $lat:float8,
          $long:float8
          $address:jsonb,
          ) {
  
          insert_visits(objects: {
            visit_time: $visit_time,
            visit_status: $visit_status,
            first_name: $first_name,
            last_name: $last_name,
            visit_loan_type:$visit_loan_type,
            space_id:$space_id,
            mobile_number: $mobile_number,
            visit_type: $visit_type,
            safe_primary_user_id: $primary_user_id,
            lat:$lat,
            long:$long,
            address:$address
            }) {
              affected_rows
              returning {
              visit_display_id
              id
            }
          }
        }
        `,
      };
      setState({ isSubmitting: true, isError: false });
      HasuraApi.post("/v1/graphql", createVisitQuery)
        .then((data) => {
          if (data?.data) {
            setState({ isSubmitting: false });
            Notification.create("success", "Visit Created Successfully");
            history.push("/visits");
          }
        })
        .catch((err) => setState({ isSubmitting: false, isError: true }));
    } else {
      createExistingCustomerVisit();
    }
  };

  const selectLocker = (locker_id) => {
    const { lockers } = state;
    const data = JSON.parse(JSON.stringify(lockers));
    Object.keys(lockers)?.forEach((key) => {
      data[key] = data[key]?.map((locker) =>
        locker?.id === locker_id
          ? { ...locker, isSelected: !locker?.isSelected }
          : locker
      );
    });
    setState({ lockers: data });
  };

  const disabledHours = () => {
    const currentHour =
      state?.date?.get("day") === moment().get("day")
        ? moment().get("hours")
        : -1;
    const hours = [];
    for (let i = 0; i < currentHour; i++) {
      hours.push(i);
    }
    return hours;
  };
  const disabeleMinutes = (hour) => {
    const currentMinute =
      hour === moment().get("hours") ? moment().get("minutes") : -1;
    const minutes = [];
    for (let i = 0; i < currentMinute; i++) {
      minutes.push(i);
    }
    return minutes;
  };

  const {
    lockers,
    visitors,
    customerType,
    spaces,
    formError,
    isSearching,
    isSubmitting,
    isLoading,
  } = state;
  const { firstName, lastName, mobile, date, time, visitType, spaceID, name } =
    state;
  const { visitor } = state;

  if (isLoading) return <Loading />;
  return (
    <div className="space-y-10">
      <div>
        <p className="text-header-3">Create New Visit</p>
      </div>
      <div className="flex lg:flex-row flex-col items-start">
        <p className="text-header-3 p-5 lg:w-1/5 uppercase">choose customer</p>
        <PrimaryCard className="p-10 w-full md:w-3/4 xl:w-3/5">
          <Input
            value={customerType}
            type="radioGroup"
            name="customer_type"
            variant="green"
            onChange={(e) => setState({ customerType: e })}
            options={[
              {
                label: "New Customer",
                value: "new",
                className: customerType === "existing" ? "text-subtle" : "",
              },
              {
                label: "Existing Customer",
                value: "existing",
                className: customerType === "new" ? "text-subtle" : "",
              },
            ]}
          />
        </PrimaryCard>
      </div>
      <div className="flex flex-col lg:flex-row items-start">
        <p className="text-header-3 p-5 lg:w-1/5 uppercase">create Visit</p>
        <PrimaryCard className="p-10 w-full md:w-3/4 xl:w-3/5">
          <Form
            customValidation={validation}
            onSubmit={handleSubmit}
            className="space-y-10"
          >
            {customerType === "new" ? (
              <div className="space-y-5">
                <div className="flex gap-6 flex-col md:flex-row">
                  <LabelInput
                    label="first name"
                    type="text"
                    placeholder="Enter Customer First Name"
                    rootClassName="w-1/2"
                    value={firstName}
                    onChange={(e) => onChange("firstName", e)}
                    required
                  />
                  <LabelInput
                    label="last name"
                    type="text"
                    placeholder="Enter Customer Last Name"
                    rootClassName="w-1/2"
                    value={lastName}
                    onChange={(e) => onChange("lastName", e)}
                    required
                  />
                </div>
                <LabelInput
                  label="mobile number"
                  maxLength={10}
                  type="numberText"
                  placeholder="Enter 10 digit mobile number"
                  rootClassName="w-3/4 lg:w-1/2"
                  value={mobile}
                  onChange={(e) => onChange("mobile", e)}
                  required
                />
                <div className="flex space-x-5">
                  <div className="space-y-4">
                    <p className="label">VISIT DATE &amp; TIME</p>
                    <div className="flex gap-6 flex-wrap">
                      <DatePicker
                        format="DD-MM-YYYY"
                        disabledDate={(date) =>
                          date <= moment().subtract(1, "day")
                        }
                        className="rounded-lg p-3"
                        value={date}
                        onChange={(value) => (
                          onChange("time", null), onChange("date", value)
                        )}
                      />
                      <TimePicker
                        disabled={!date}
                        disabledHours={disabledHours}
                        disabledMinutes={disabeleMinutes}
                        className="rounded-lg p-3"
                        format="HH:mm"
                        minuteStep={5}
                        value={time}
                        onChange={(value) => onChange("time", value)}
                      />
                    </div>
                  </div>
                  <LabelInput
                    label="Space ID"
                    type="select"
                    options={spaces?.map((space) => ({
                      label: space?.space_number + " - " + space?.name,
                      value: space?.id,
                    }))}
                    placeholder="Choose Space ID"
                    value={spaceID}
                    onChange={(e) => onChange("spaceID", e)}
                  />
                </div>
                <LabelInput
                  rootClassName="w-1/2"
                  label="visit type"
                  type="select"
                  options={[
                    {
                      label: "Call",
                      value: "SAFE_CUSTOMER_ONBOARD_VISIT/CALL",
                    },
                    {
                      label: "WalkIn",
                      value: "SAFE_CUSTOMER_ONBOARD_VISIT/WALKIN",
                    },
                    {
                      label: "Web",
                      value: "SAFE_CUSTOMER_ONBOARD_VISIT/WEB",
                    },
                  ]}
                  placeholder="Call/Walkin/Web"
                  value={visitType}
                  onChange={(e) => onChange("visitType", e)}
                  required
                />
              </div>
            ) : (
              <div className="space-y-5">
                <div className="flex space-x-5 items-end">
                  <LabelInput
                    label="mobile number"
                    maxLength={10}
                    type="numberText"
                    rootClassName="w-3/4 lg:w-1/2"
                    placeholder="Search using mobile number"
                    value={mobile}
                    onChange={(e) => onChange("mobile", e)}
                    required
                  />
                  <Button
                    variant="primary"
                    isLoading={isSearching}
                    onClick={searchUsingMobile}
                  >
                    Search
                  </Button>
                </div>
                <div className="flex gap-6 flex-wrap">
                  <LabelInput
                    label="Primary Customer Name"
                    type="text"
                    placeholder="Primary Customer Name here"
                    value={name}
                    required
                  />
                  <LabelInput
                    label="Choose visitor"
                    LabelInputClassName="p-2 text-light-gray"
                    type="select"
                    options={
                      visitors?.map((visitor) => ({
                        label: `${visitor?.name}-${visitor?.type}`,
                        value: visitor?.id,
                      })) ?? []
                    }
                    placeholder="Primary/Secondary/Tertiary"
                    value={visitor}
                    onChange={(e) => onChange("visitor", e)}
                    required
                  />
                </div>
                <div className="flex items-center gap-6 flex-wrap">
                  <div className="space-y-4">
                    <p className="label">VISIT DATE &amp; TIME</p>
                    <div className="flex gap-5 flex-wrap items-center">
                      <DatePicker
                        format="DD-MM-YYYY"
                        disabledDate={(date) =>
                          date <= moment().subtract(1, "day")
                        }
                        className="rounded-lg p-3"
                        value={date}
                        onChange={(value) => (
                          onChange("time", null), onChange("date", value)
                        )}
                      />
                      <TimePicker
                        disabled={!date}
                        disabledHours={disabledHours}
                        disabledMinutes={disabeleMinutes}
                        className="rounded-lg p-3"
                        format="HH:mm"
                        minuteStep={5}
                        value={time}
                        onChange={(value) => onChange("time", value)}
                      />
                    </div>
                  </div>
                  <LabelInput
                    label="Space ID"
                    type="select"
                    options={spaces?.map((space) => ({
                      label: space?.space_number + " - " + space?.name,
                      value: space?.id,
                    }))}
                    placeholder="Choose Space ID"
                    value={spaceID}
                    onChange={(e) => onChange("spaceID", e)}
                  />
                </div>
                {lockers && (
                  <div className="space-y-2">
                    <p className="label">Choose Lockers To Visit</p>
                    <div className="flex gap-10 md:gap-20  flex-wrap">
                      {lockers &&
                        Object.keys(lockers).map((key, index) => (
                          <div key={key} className="space-y-5">
                            <p className="text-xs font-semibold">{key} type</p>
                            <div className="space-y-4">
                              {lockers[key]?.map(
                                (
                                  { id: locker_id, locker_number, isSelected },
                                  index
                                ) => (
                                  <div key={locker_id} className="flex gap-8">
                                    <CheckBox
                                      onClick={() => selectLocker(locker_id)}
                                      isSelected={isSelected}
                                      className="mt-2"
                                    />
                                    <p
                                      onClick={() => selectLocker(locker_id)}
                                      className="text-xs font-semibold"
                                    >
                                      {locker_number}
                                    </p>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
                <LabelInput
                  label="visit type"
                  type="select"
                  rootClassName="w-1/2"
                  options={[
                    {
                      label: "Call",
                      value: "SAFE_VISIT/CALL",
                    },
                    {
                      label: "WalkIn",
                      value: "SAFE_VISIT/WALKIN",
                    },
                    {
                      label: "Web",
                      value: "SAFE_VISIT/WEB",
                    },
                  ]}
                  placeholder="Call/Walkin/Web"
                  value={visitType}
                  onChange={(e) => onChange("visitType", e)}
                  required
                />
              </div>
            )}
            <div className="flex justify-center items-center gap-5 flex-col-reverse md:flex-row">
              <Link
                variant="normal"
                className="w-3/4 md:w-1/2 text-center"
                to="/visits"
              >
                Cancel
              </Link>
              <Button
                type="submit"
                isLoading={isSubmitting}
                className="w-3/4 md:w-1/2"
                variant="cta"
              >
                Create New Visit
              </Button>
            </div>
          </Form>
          <p className="text-error-red mt-2">{formError}</p>
        </PrimaryCard>
      </div>
    </div>
  );
}
