import { getValueFromCookie, addCookie, removeCookie } from "./cookies";

const AuthUtils = {
  getToken() {
    return getValueFromCookie("token");
  },
  getRefreshToken() {
    return getValueFromCookie("refreshToken");
  },
  setToken(token) {
    addCookie("token", token);
  },
  login(data) {
    addCookie("token", data?.token);
    addCookie("refreshToken", data?.refreshToken);
  },
  logout() {
    removeCookie("token");
    removeCookie("refreshToken");
    window.location.reload();
  },
  isLoggedIn() {
    if (getValueFromCookie("token") && getValueFromCookie("refreshToken"))
      return true;
    return false;
  },
};

export default AuthUtils;
