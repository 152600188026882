import React from "react";
import GoogleLogin from "react-google-login";
import AuthApi from "../utils/ApiUtils/AuthApi";
import AuthUtils from "../utils/AuthUtils";
import GoogleIcon from '../assets/google.svg';
import jwt_decode from "jwt-decode";
import Notification from '../components/Notifications';

export default function Login({ setAuthenticated }) {
  const handleSuccess = (e) => {
    AuthApi.googleVerifyToken(e.tokenId).then((res) => {
      try {
        const decodedToken = jwt_decode(res.user.token);
        if (res.code === '200' && decodedToken.allowed_clients.includes("SAFE")) {
          AuthUtils.login({
            token: res.user.token,
            refreshToken: res.user.refreshToken,
          });
          setAuthenticated(true);
        } else {
          if (res.code === '200')
            Notification.create('error', "Check if you have the relevant permissions and try again.");
          else
            Notification.create('error', "Something went wrong.");
        }
      } catch (e) {
          Notification.create('error', "Something went wrong.");
      }
    });
  };

  const handleFailure = (e) => {
    Notification.create('error', e);
  };

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}
      render={({ onClick }) => (
        <div
          onClick={onClick}
          className="flex px-10 py-4 2xl:px-12 flex-row space-x-3  text-xl rounded-lg
          justify-center mt-2  border-thin border-yellow-400 cursor-pointer shadow-xl "
        >
          <img src={GoogleIcon} alt="google-icon" />
          <p className="text-body-large ">Signin with Google</p>
        </div>
      )}
      onSuccess={handleSuccess}
      onFailure={handleFailure}
      cookiePolicy="single_host_origin"
    />
  );
}
