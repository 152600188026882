/* eslint-disable camelcase */
/* eslint-disable react/jsx-handler-names */
import React, { useState } from "react";
import PrimaryCard from "../../../../components/Cards/PrimaryCard";
import Form from "../../../../components/Form/Form";
import LabelInput from "../../../../components/Form/LabelInput";
import { DatePicker, TimePicker } from "antd";
import { indianStates } from "../../../../utils";
import Link from "../../../../components/Link";
import Button from "../../../../components/Buttons/Button";
import HasuraApi from "../../../../utils/ApiUtils/HasuraApi";
import moment from "moment";
import jwtDecode from "jwt-decode";
import AuthUtils from "../../../../utils/AuthUtils";
import { disabeleMinutes, disabledHours } from '../../CreateVisit/CreateDoorstepVisit/utils';
import { getConfirmVisitQuery } from "./constants";
import Modal from "../../../../components/Modal";
import { ReactComponent as WarningIcon } from "../../../../assets/warning.svg";

export default function ConfirmVisitFormCard({ visitData, onUpdateStatus }) {
  const {visit_time, address, id} = visitData;
  const visitConfirmedAtDate = moment(visit_time);
  const visitConfirmedAtTime = moment(visit_time);
  const [state, setStateHelper] = useState({
    isLoading: true,
    isError: false,
    isSubmitting: false,
    isSearching: false,
    showCancelVisitModal: false,
    isCanceling: false,
    formError: "",
    addressLine1: address?.addr_line_1 ?? "",
    addressLine2: address?.addr_line_2 ?? "",
    city: address?.city ?? "",
    selectedState: address?.state ?? "",
    pincode: address?.pincode ?? "",
    latitude: address?.lat ?? "",
    longitude: address?.long ?? "",
    date: visitConfirmedAtDate,
    time: visitConfirmedAtTime
  });

  const setState = (changes) => {
    setStateHelper((prevState) => ({
      ...prevState,
      ...changes
    }));
  };

  const {
    addressLine1,
    addressLine2,
    city,
    selectedState,
    pincode,
    latitude,
    longitude,
    date,
    time,
    isSubmitting,
    showCancelVisitModal,
    isCanceling,
    formError
  } = state;

  const onConfirmVisit = () => {
    const visitDate = moment(date).format("DD/MM/YYYY");
    const visitTime = moment(time).format("HH:mm");
    const visitDateTime = moment(
      `${visitDate} ${visitTime}`,
      "DD/MM/YYYY HH:mm"
    );

    // generate request variables for graphql query
    const req_obj = {
      id,
      visit_time: moment(visitDateTime),
      lat: latitude,
      long: longitude,
      address: {
        ...address,
        addr_line_1: addressLine1,
        addr_line_2: addressLine2,
        city,
        state: selectedState,
        pincode,
        lat: latitude,
        long: longitude
      },
      confirmed_at: moment(),
      confirmed_by: jwtDecode(AuthUtils.getToken()).user_id
    };

    // fetching the confirm visit query
    const confirmVisitQuery = getConfirmVisitQuery(req_obj);

    setState({ isSubmitting: true, isError: false });
    HasuraApi.post("/v1/graphql", confirmVisitQuery)
      .then((data) => {
        setState({
          isSubmitting: false,
          isError: false
        });
        onUpdateStatus(data?.data?.update_visits?.returning[0]);
      })
      .catch(() => {
        setState({ isSubmitting: false, isError: true, retry: onConfirmVisit });
      });

  };

  const cancelVisit = () => {
		const cancelVisitQuery = {
			variables: {
				visit_id: id,
			},
			query: `
      mutation cancelVisit(
        $visit_id: Int!
      ) {
        update_visits_by_pk(
          pk_columns: { id: $visit_id }
          _set: {
            visit_status: "VISIT_CANCELLED"
          }
        ) {
          id
          visit_status
          cancellation_reason
          cancelled_by {
            id
            first_name
            last_name
          }
        }
      }
    `,
		};
		setState({isCanceling: true, isError: false });
		HasuraApi.post("/v1/graphql", cancelVisitQuery)
			.then((data) => {
				setState({
          isCanceling: false,
					isError: false,
					showCancelVisitModal: false,
				});
        onUpdateStatus(data?.data?.update_visits_by_pk);
			})
			.catch((err) => {
				setState({ isCanceling: false, isError: true, retry: cancelVisit });
			});
	};

  const validation = () => {
    if (!state.date) {
      setState({ formError: "Please Select Date" });
      return false;
    }
    if (!state.time) {
      setState({ formError: "Please Select Time" });
      return false;
    }
    setState({ formError: "" });
    return true;
  };

  const onChange = (type, e) => {
    switch (type) {
    case "addr_line_1":
      setState({ addressLine1: e.target.value });
      break;
    case "addr_line_2":
      setState({ addressLine2: e.target.value });
      break;
    case "state":
      setState({ selectedState: e.target.value });
      break;
    case "city":
      setState({ city: e.target.value });
      break;
    case "pincode":
      setState({ pincode: e });
      break;
    case "date":
      setState({ date: e });
      break;
    case "time":
      setState({ time: e });
      break;
    case "latitude":
      setState({ latitude: e });
      break;
    case "longitude":
      setState({ longitude: e });
      break;
    default:
    }
  };

  return (
    <PrimaryCard className="p-10 w-full md:w-3/4 xl:w-3/5">
      <Form customValidation={validation} onSubmit={onConfirmVisit}>
        {/* // CUSTOMER ADDRESS */}
        <div className="flex flex-col space-y-4 pt-6">
          <p className="label">Customer Address</p>
          <LabelInput
            type="text"
            placeholder="Address line 1"
            value={addressLine1}
            required
            onChange={(e) => onChange("addr_line_1", e)}
          />
          <LabelInput
            type="text"
            placeholder="Address line 2"
            value={addressLine2}
            onChange={(e) => onChange("addr_line_2", e)}
          />
          <div className="flex gap-5 flex-wrap">
            <LabelInput
              type="numberText"
              placeholder="Pincode"
              rootClassName="w-1/4"
              value={pincode}
              required
              onChange={(e) => onChange("pincode", e)}
              maxLength={6}
            />
            <LabelInput
              type="text"
              placeholder="City"
              rootClassName="w-1/4"
              value={city}
              required
              onChange={(e) => onChange("city", e)}
            />
            <LabelInput
              type="select"
              placeholder="State"
              rootClassName="w-1/4"
              value={selectedState}
              options={indianStates}
              required
              onChange={(e) => onChange("state", e)}
            />
          </div>
        </div>
        {/* // LATITUDE AND LONGTITUDE */}
        <div className="flex gap-5 flex-wrap pt-6">
          <LabelInput
            label="latitude"
            maxLength={8}
            type="numberText"
            placeholder="Enter Lat"
            value={latitude}
            onChange={(e) => onChange("latitude", e)}
            required
          />
          <LabelInput
            label="longitude"
            maxLength={8}
            type="numberText"
            placeholder="Enter Long"
            value={longitude}
            onChange={(e) => onChange("longitude", e)}
            required
          />
        </div>
        {/* DATE AND TIME PICKER  */}
        <div className="space-y-4 pt-6">
          <p className="label">VISIT DATE &amp; TIME</p>
          <div>
            <div className="flex gap-6 flex-wrap">
              <DatePicker
                format="DD-MM-YYYY"
                disabledDate={(date) => date <= moment().subtract(1, "day")}
                className={`rounded-lg p-3 border ${
                  formError ? "border-error-red" : ""
                }`}
                value={date}
                onChange={(value) => (
                  onChange("time", null), onChange("date", value)
                )}
              />
              <TimePicker
                disabled={!date}
                disabledHours={disabledHours}
                disabledMinutes={disabeleMinutes}
                className={`rounded-lg p-3 border ${
                  formError ? "border-error-red" : ""
                }`}
                format="HH:mm"
                minuteStep={5}
                value={time}
                onChange={(value) => onChange("time", value)}
              />
            </div>
            <p className="text-error-red mt-2">{formError}</p>
          </div>
        </div>
        <div className="flex justify-center items-center gap-5 flex-col-reverse md:flex-row pt-10">
          <Button
            variant='secondary'
            className='bg-white rounded-full w-3/4 md:w-1/2 py-3.5'
            onClick={() => setState({ showCancelVisitModal: true })}
          >
              Cancel
          </Button>
          <Button
            type="submit"
            isLoading={isSubmitting}
            className="w-3/4 md:w-1/2"
            variant="cta"
          >
            Confirm Visit
          </Button>
        </div>
      </Form>
      {showCancelVisitModal && (
				<Modal
					className='p-10 flex flex-col space-y-10  align-middle text-center
        w-3/4 md:w-2/5 xl:w-1/3 2xl:w-1/4'
					onClose={() => setState({ showCancelVisitModal: false })}
				>
					<WarningIcon className='mb-5 mx-auto' />
					<div className='flex space-y-1 flex-col'>
						<p className='text-body-large'>Warning!</p>
						<p className='text-body-large w-3/4 mx-auto'>
							{' '}
							Are you sure you want to cancel the visit
						</p>
					</div>
					<div className='flex space-x-5 justify-center '>
						<Button
							variant='normal'
							className='w-1/2'
							isLoading={isCanceling}
							onClick={cancelVisit}
						>
							Cancel
						</Button>
						<Button
							variant='cta'
							className='w-1/2'
							onClick={() => setState({ showCancelVisitModal: false })}
						>
							Don't Cancel
						</Button>
					</div>
				</Modal>
			)}
    </PrimaryCard>
  );
}
