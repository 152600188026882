import React, {useState, useEffect } from 'react';
import { Table } from 'antd';
import { useParams } from 'react-router-dom';
import Modal from '../../components/Modal';
import Form from '../../components/Form/Form';
import Button from '../../components/Buttons/Button';
import LabelInput from '../../components/Form/LabelInput';
import Api from '../../utils/ApiUtils/Api';
import Loading from '../../components/loading';

const LockerTypePricing = () => {
  const [state, setStateHelper] = useState({
    price: null,
    planId: null,
    security_deposit: null,
    pricingData: [],
    isLoading: true,
    isError: false,
    priceDate: null,
    formPrice: '',
    formSecurityDeposit: '',
    editPricingModal: false,
    lockerTypeName: null,
    lockerTypeDim: null,
    isSubmitting: false,
  });
  const { spaceId, lockerTypeId } = useParams();

  const setState = (changes) => {
    setStateHelper((prev) => ({
      ...prev,
      ...changes,
    }));
  }

  const getPricing = () => {
    setState({isLoading: true})

    Api.get(`/locker-price-plan/${lockerTypeId}/space/${spaceId}`).then(
      (data) => {
        setState({isLoading: false, pricingData: data?.lockerPlan?.rows, lockerTypeName: data?.lockerPlan?.locker_type, 
          lockerTypeDim: data?.lockerPlan?.locker_type_size})
      },
    ).catch(err => setState({isLoading: false, isError: true}));
  };

  const updatePricing = () => {
    setState({isSubmitting: true})
    Api.put(`locker-price-plan/${state?.planId}`, { price: state?.formPrice, security_deposit: state?.formSecurityDeposit }).then(
      (data) => {
        setState({isSubmitting: false, editPricingModal: false, formPrice: '', formSecurityDeposit: ''})
        getPricing();
      },
    ).catch(err => setState({isSubmitting: false, isError: true}));
  };

  useEffect(() => {
    getPricing();
  }, []);

  const getTenure = (value) => {
    const years = Math.floor(value / 12);
    const months = value % 12;
    const monthString = months === 1 ? `${months} Month` : `${months} Months`;
    if (years > 0) {
      const yearString = years === 1 ? `${years} Year` : `${years} Years`;

      return months === 0 ? yearString : `${yearString} ${monthString}`;
    }
    return monthString;
  };

  const columns = [
    {
      title: 'Tenure',
      dataIndex: 'tenure',
      key: 'tenure',
      render: (text) => <p className="text-body-bold capitalize">{(getTenure(text))}</p>,
    },
    {
      title: 'Pricing',
      dataIndex: 'price',
      key: 'pricing',
      render: (text) => <p className="text-body-bold capitalize">{`₹${text}`}</p>,
    },
    {
      title: 'Security Deposit',
      dataIndex: 'security_deposit',
      key: 'security_deposit',
      render: (text) => <p className="text-body-bold capitalize">{`₹${text}`}</p>,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, data) => (
        <Button variant="secondary" className="text-xs font-bold" onClick={() => {
          setState({
            price: data?.price,
            planId: data?.id,
            security_deposit: data?.security_deposit,
            tenure: data?.tenure,
            editPricingModal: true
          });
        }}
        >
          Edit Pricing
        </Button>
      ),
    },
  ];

  const { price, security_deposit, tenure, isLoading, isSubmitting, isError, lockerTypeName, 
    lockerTypeDim, pricingData, editPricingModal, formPrice, formSecurityDeposit} = state;

  if (isLoading) return <Loading />;
  if(isError) return (
    <div className="flex flex-col space-y-2 font-bold h-1/2 items-center justify-center">
      <p>Something Went Wrong</p>
      <p>Please Try Again</p>
      <Button variant="primary" onClick={getPricing}>Retry</Button>
    </div>
  )

  return (
    <>
      <div className="space-y-10">
        <p className="text-header-3">{lockerTypeName} - {lockerTypeDim} - Pricing Details</p>
        <Table className="w-3/4 mx-auto" columns={columns} dataSource={pricingData} pagination={{ position: ['bottomCenter'] }} />
      </div>
      {editPricingModal && (
        <Modal title="Edit Pricing" className="p-10" onClose={() => setState({editPricingModal: false})}>
          <Form onSubmit={updatePricing} className="space-y-10">
            <p className="text-label font-bold text-base mb-8 mr-56">
              CURRENT PRICE : {getTenure(tenure)} - <span className="lowercase">
                ₹{price}
                {' '}
                | security deposit -
                {' '}
                ₹{security_deposit}
              </span>
            </p>
            <LabelInput label="Enter New Price" type="numberText" className="text-base pr-4 w-5/12"
              placeholder="Enter price" value={formPrice}
              onChange={(e) => setState({ formPrice: e })} required
            />
            <LabelInput label="Enter New Security Deposit" type="numberText" className="text-base pr-4 w-5/12"
              placeholder="Enter Security Deposit" value={formSecurityDeposit}
              onChange={(e) => setState({ formSecurityDeposit: e })} required
            />
            <div className="flex justify-center mt-8 space-x-5">
              <Button variant="normal" className="w-1/2" onClick={() => setState({editPricingModal: false})}>Cancel</Button>
              <Button variant="cta" className="w-1/2" type="submit" isLoading={isSubmitting}>Update Pricing</Button>
            </div>
          </Form>
        </Modal>
      )}
    </>
  );
};
export default LockerTypePricing;
