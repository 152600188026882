import React, { useEffect, useState } from 'react';
import { TimePicker } from 'antd';
import moment from 'moment';
import Form from '../../components/Form/Form';
import PrimaryCard from '../../components/Cards/PrimaryCard';
import Button from '../../components/Buttons/Button';
import Checkbox from '../../components/Checkbox/Checkbox';
import LabelInput from '../../components/Form/LabelInput';
import { indianStates } from '../../utils';
import Api from '../../utils/ApiUtils/Api';
import Loading from '../../components/loading';
import Notification from '../../components/Notifications';
import { getCityAndStateDataFromPincode } from '../../utils'
import permissions from '../../utils/permissionConfig';
import AccessCheck from '../../routes/accessCheck';

export default function CreateSpace({ history }) {
  const [state, setStateHelper] = useState({
    isLoading: false,
    isSubmitting: false,
    isError: false,
    formError: '',
    cities: [],
  });

  function setState(changes) {
    setStateHelper((prev) => ({
      ...prev,
      ...changes,
    }));
  }

  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const getCities = () => {
    setState({ isLoading: true, isError: false });
    Api.get('space-config/city').then((data) => {
      setState({ cities: data?.city, isLoading: false });
    }).catch(err => setState({ isLoading: false, isError: true }));
  }

  useEffect(() => {
    getCities();
  }, []);

  useEffect(() => {
    if (state?.pincode?.length === 6) {
      const autoFillCityAndStateUsingPincode = async () => {
        const { cityName, stateName } = await getCityAndStateDataFromPincode(state?.pincode);

        if (cityName) setState({ city: cityName });
        if (stateName) setState({ state: stateName });
      };

      autoFillCityAndStateUsingPincode();
    }
  }, [state?.pincode]);

  const onChange = (type, e) => {
    switch (type) {
      case "space":
        setState({ spaceName: e.target.value });
        break;
      case "addressLine1":
        setState({ addressLine1: e.target.value });
        break;
      case "addressLine2":
        setState({ addressLine2: e.target.value });
        break;
      case "state":
        setState({ state: e.target.value });
        break;
      case "city":
        setState({ city: e.target.value });
        break;
      case "pincode":
        setState({ pincode: e });
        break;
      case "latitude":
        setState({ latitude: e });
        break;
      case "longitude":
        setState({ longitude: e });
        break;
      case "businessFrom":
        setState({ [e.day]: { ...state[e.day], from: e.from } });
        break;
      case "businessTo":
        setState({ [e.day]: { ...state[e.day], to: e.to } });
        break;
      case "businessHoliday":
        setState({ [e.day]: { ...state[e.day], isHoliday: e.isHoliday } });
        break;
      default:
    }
  }

  const handleSubmit = () => {
    const { cities } = state;
    const body = {
      name: state?.spaceName,
      city_id: state?.city,
      address: {
        lat: parseFloat(state?.latitude),
        long: parseFloat(state?.longitude),
        city: cities.filter((el) => el.id === parseInt(state?.city, 10))[0]?.name,
        addr_line_1: state?.addressLine1,
        addr_line_2: state?.addressLine2,
        state: state?.state,
        country: "India",
        pincode: state?.pincode,
      },
      business_hours: days?.map((day, index) => {
        if (!state[day]?.isHoliday) {
          return ({
            day: index,
            open_time: moment(state[day].from).format('HH:mm'),
            close_time: moment(state[day].to).format('HH:mm'),
            holiday: state[day].isHoliday ?? false
          });
        }
        return ({ day: index, holiday: true });
      }),

    };
    setState({ isSubmitting: true, isError: false });
    Api.post('space-config/space', body).then((data) => {
      setState({ isSubmitting: false });
      Notification.create('success', 'space created successfully')
      history.push('/spaces');
    }).catch(err => setState({ isSubmitting: false, isError: true }));
  };

  const isValid = () => {
    days.forEach((day) => {
      if (!state[day]?.isHoliday && (!state[day]?.from || !state[day]?.to)) {
        setState({ formError: 'Please Check All Fields' });
        return false;
      }
    });
    if (state?.pincode?.length !== 6) {
      setState({ formError: 'Please Check Pincode' });
      return false;
    }

    return true;
  };

  const { spaceName, addressLine1, addressLine2, city, pincode, latitude, longitude, isLoading, isError,
    cities, formError, isSubmitting } = state;
  if (isLoading) return <Loading />;
  return (
    <div className="space-y-10">
      <p className="text-header-3">Create New Space</p>
      <Form customValidation={isValid} onSubmit={handleSubmit} className="space-y-10">
        <div className="flex flex-col lg:flex-row">
          <div className="text-header-3 p-5 w-1/5 uppercase">Space Address</div>
          <PrimaryCard className="w-full md:w-5/6 xl:w-3/5 2xl:w-1/2 space-y-10 p-10">
            <LabelInput label="space name" placeholder="Enter Space Name here" type="text" value={spaceName}
              onChange={(e) => onChange('space', e)} required
            />
            <div>
              <p className="label mb-4">Location</p>
              <div className="space-y-5">
                <LabelInput placeholder="Address line 1" type="text" value={addressLine1}
                  onChange={(e) => onChange('addressLine1', e)} required
                />
                <LabelInput placeholder="Address line 2" type="text" value={addressLine2}
                  onChange={(e) => onChange('addressLine2', e)}
                />
                <div className="flex gap-5 flex-wrap">
                  <LabelInput maxLength={6} placeholder="pincode" type="numberText"
                    value={pincode} onChange={(e) => onChange('pincode', e)} required
                  />
                  <LabelInput type="select" placeholder="City" value={city} rootClassName="w-1/4"
                    options={cities?.map((city) => ({ value: city?.id, label: city?.name }))}
                    onChange={(e) => onChange('city', e)} required disabled
                  />
                  <LabelInput LabelInputClassName="text-base text-light-gray p-2" placeholder="state" type="select"
                    options={indianStates} value={state.state}
                    onChange={(e) => onChange('state', e)} required disabled
                  />
                </div>
                <div className="flex gap-5 flex-wrap">
                  <LabelInput label="latitude" maxLength={8} type="numberText" value={latitude}
                    onChange={(e) => onChange('latitude', e)} required
                  />
                  <LabelInput label="longitude" maxLength={8} type="numberText" value={longitude}
                    onChange={(e) => onChange('longitude', e)} required
                  />
                </div>
              </div>
            </div>
          </PrimaryCard>
        </div>
        <div className="flex flex-col lg:flex-row">
          <p className="text-header-3 p-5 w-1/5 uppercase">Space Address</p>
          <PrimaryCard className="w-full md:w-5/6 xl:w-3/5 2xl:w-1/2 space-y-5 p-10">
            <p className="label">
              set business hours
            </p>
            {days.map((day, index) => (
              <>
                <p className="label">
                  {day}
                </p>
                <div className="flex gap-5 flex-wrap items-center">
                  <TimePicker disabled={state[day]?.isHoliday} className="rounded-lg p-3" format="HH:mm" minuteStep={15}
                    value={state[day]?.from} onChange={(time) => onChange('businessFrom', { day, from: time })}
                  />
                  <span className="text-light-gray px-5">to</span>
                  <TimePicker disabled={state[day]?.isHoliday} className="rounded-lg p-3" format="HH:mm" minuteStep={15}
                    value={state[day]?.to} onChange={(time) => onChange('businessTo', { day, to: time })} required
                  />
                  <div className="flex items-center">
                    <Checkbox isSelected={state[day]?.isHoliday}
                      onClick={() => onChange('businessHoliday', { day, isHoliday: !state[day]?.isHoliday })}
                      className="mr-10"
                    />
                    <p className="text-body-bold">Mark as Holiday</p>
                  </div>
                </div>
              </>
            ))}
          </PrimaryCard>
        </div>
        <div className="text-center space-y-2 mx-auto">
          <AccessCheck
            requiredPermissions={permissions.spaces.create_new_space.permission}
            alternateChildren={<div></div>}
            looseCheck={false}
          >
            <Button variant="cta" type="submit" className="w-3/4 lg:w-1/2 mx-auto" isLoading={isSubmitting}>
              Create New Space
            </Button>
          </AccessCheck>
          <p className="text-error-red text-body-normal">{formError}</p>
        </div>
      </Form>
    </div>
  );
}
