import React from "react";
import jwt_decode from "jwt-decode";


import { deleteAllCookies, getValueFromCookie } from "../utils/cookies";

const PermissionContext = React.createContext({
  permissions: [],
});

export function PermissionsProvider({ children }) {
  const [permissions, updatePermissions] = React.useState([]);
  const value = React.useMemo(() => ({ permissions }), [permissions]);

  const updatePermissionsFromToken = () => {
    // get token from cookie
    const token = getValueFromCookie("token");
    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        if (decodedToken) {
          const permissionArray = decodedToken.permissions;
          // get and update permissions here from user token after decoding
          updatePermissions(permissionArray);
        }
      } catch (e) {
        deleteAllCookies();
      }
    }
  };

  React.useEffect(() => {
    updatePermissionsFromToken();
  }, [getValueFromCookie("token")]);

  return (
    <PermissionContext.Provider value={value}>
      {children}
    </PermissionContext.Provider>
  );
}

export function GetUserPermissions() {
  const context = React.useContext(PermissionContext);

  if (context === undefined) {
    throw new Error(
      "getUserPermissions must be used within PermissionsProvider"
    );
  }

  return context;
}
