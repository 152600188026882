import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PrimaryCard from '../../components/Cards/PrimaryCard';
import KeyValue from '../../components/Form/KeyValue';
import Button from '../../components/Buttons/Button';
import Api from '../../utils/ApiUtils/Api';
import Loading from '../../components/loading';
import ImageModal from '../../components/ImageModal';
import { getCustomerDisplayId, getFullName, getVisitDisplayId } from '../../utils';
import { capitalize } from 'lodash';
import permissions from '../../utils/permissionConfig';
import AccessCheck from '../../routes/accessCheck';


const VisitCard = ({ id, visit_time, visitor_name, user_type, lockers, visit_type }) => {
  const getLockersList = (lockers) => (lockers?.map((locker, index) => locker?.visitXLockers?.locker_number).join('; '));
  return (

    <PrimaryCard className="space-y-5 xl:w-1/4 2xl:w-1/5">
      <div className="flex justify-between items-center">
        <AccessCheck
          requiredPermissions={permissions.customers.table_row.visit_id_details.permission}
          alternateChildren={
            <KeyValue label="visit id" value={getVisitDisplayId(id)} class=" underline" />
          }
          looseCheck={false}
        >
          <KeyValue label="visit id" value={getVisitDisplayId(id)} link={`/visits/locker-visit-details/${id}`} class=" underline" />
        </AccessCheck>
        <Button className="bg-subtle px-2 py-2 text-xs font-bold text-white  capitalize rounded-lg">
          {visit_type === 'SAFE_VISIT' ? 'Term Visit' : 'Pre-Term Visit'}</Button>
      </div>
      <KeyValue label="Visit date and time" value={moment(visit_time).format("DD-MM-YYYY | HH:mm")} />
      <div className="space-y-2">
        <p className="small-label">Visited By </p>
        <div className="flex space-x-2">
          <p className="text-body-normal">{visitor_name}</p>
          <p className="bg-medium-yellow px-2 py-1 text-xs font-bold rounded-lg">{user_type}</p>
        </div>
      </div>
      <KeyValue label="Lockers visited" value={getLockersList(lockers)} />
    </PrimaryCard>
  );
};
const LockerBar = ({ space_name, locker_type, locker_size, subscriptions, locker_length, locker_height, locker_width }) => (
  <PrimaryCard className="bg-medium-yellow flex space-x-5 items-center w-full md:w-3/4 xl:w-1/2">
    <KeyValue label="Space Name" labelStyle="text-black" value={space_name} />
    <KeyValue label="Locker Type" labelStyle="text-black" value={locker_type} />
    <KeyValue label="Dimensions (in Cm)" labelStyle="text-black" value={`${locker_width}X${locker_height}X${locker_length}`} />
    <KeyValue label="Number of Lockers" labelStyle="text-black" value={subscriptions?.length} />
    <Button className="bg-black text-white text-xs font-bold px-2 py-1 rounded-lg">{capitalize(locker_size)}</Button>
  </PrimaryCard>
);

const OroSafeCard = ({ locker_number, tenure, subscription_date, expiry_date, physical_id, payment, is_active }) => (
  <PrimaryCard className="space-y-5 xl:w-1/4 2xl:w-1/5">
    <div className="flex justify-between items-center">
      <KeyValue label="oro safe id" value={locker_number} />
      <Button className="bg-oro-yellow text-xs font-bold px-2 py-1 rounded-lg">
        {tenure === 1 ? '1 month' : `${tenure} months`}
      </Button>
    </div>
    <div className="space-y-5">
      <KeyValue label="Subscription Date" value={moment(subscription_date).format('DD-MM-YYYY')} />
      <KeyValue label="Expiry Date" value={moment(expiry_date).format('DD-MM-YYYY')} />
    </div>
    <KeyValue label="Godrej ID" value={physical_id} />
    <KeyValue label="Payment method" value={`${payment?.method ? `${payment?.method} |` : ''}
    ${payment?.external_payment_id ?? ''}`} />
    <div className="flex justify-between items-center">
      <KeyValue label="Amount Paid" value={`₹${payment?.amount}`} class="text-new-green text-base font-bold" />
      <Button className="bg-black text-white text-xs font-bold px-2 py-1 rounded-lg">{is_active ? "ACTIVE" : "INACTIVE"}</Button>
    </div>
  </PrimaryCard>
);

const CustomerCard = ({ customerType, customerData }) => {
  const { first_name, middle_name, last_name, email_id, mobile_number, userAddress = {}, kyc } = customerData;
  const [showImageModal, setImageModal] = useState(false);
  return (
    <>
      <div className="space-y-5 lg:w-1/2 xl:w-1/3 2xl:w-1/4">
        <PrimaryCard className="pr-0 space-y-5">
          <div className="flex justify-between items-start">
            <KeyValue label="customer name" value={
              <div className="flex items-center">
                <p className="text-sm mr-1">{first_name}</p>
                <p className="text-sm mr-1">{middle_name}</p>
                <p className="text-sm mr-1">{last_name}</p>
              </div>
            } />
            <span className={`${customerType === 'primaryUser' ? 'bg-medium-yellow' : 'bg-inactive'} 
        px-2 py-2 rounded-l-lg text-xs font-bold capitalize`}
            >
              {customerType.split('User')[0]} Customer
            </span>
          </div>
          <div className="flex space-x-5">
            {customerType !== 'nomineeUser' && (
              <KeyValue label="Email ID" value={email_id} />
            )}
            <KeyValue label="mobile number" value={mobile_number} />
          </div>
          <div>
            <KeyValue
              label="Address"
              value={
                <div className="space-y-1">
                  <p>{userAddress.addr_line_1}</p>
                  <p>{userAddress.addr_line_2}</p>
                  <p>{userAddress.state}</p>
                  <p>
                    {userAddress.city} - {userAddress.pincode}
                  </p>
                </div>
              }
            />
          </div>
        </PrimaryCard>
        {customerType === 'nomineeUser' ? null : (
          <PrimaryCard className="flex justify-between">
            <div className="space-y-2">
              <p>Address Proof</p>
              <Button variant="preview" onClick={() => setImageModal(kyc?.address_proof[0]?.image_url)}>
                {kyc?.address_proof[0]?.type?.split('_').join(' ')}
              </Button>
              {kyc?.address_proof[1] && (
                <Button variant="preview" onClick={() => setImageModal(kyc?.address_proof[1]?.image_url)}>
                  {kyc?.address_proof[1]?.type?.split('_').join(' ')}
                </Button>
              )}
            </div>
            <div className="space-y-2">
              <p>Recent Photo</p>
              <Button variant="preview" onClick={() => setImageModal(kyc?.live_photo)}>Recent Photo</Button>
            </div>
            <div className="space-y-2">
              <p>ID Proof</p>
              <Button variant="preview" onClick={() => setImageModal(kyc?.id_proof[0]?.image_url)}>
                {kyc?.id_proof[0]?.type.split('_').join(' ')}
              </Button>
              {kyc?.id_proof[1] && (
                <Button variant="preview" onClick={() => setImageModal(kyc?.id_proof[1]?.image_url)}>
                  {kyc?.id_proof[1]?.type?.split('_').join(' ')}
                </Button>
              )}
            </div>
          </PrimaryCard>
        )}
      </div>
      {showImageModal && (
        <ImageModal onClose={() => setImageModal(false)} imageUrl={showImageModal} />
      )}
    </>
  );
};

export default function CustomerDetails({ match }) {
  const [state, setStateHelper] = useState({
    customerData: null,
    isLoading: true,
    isError: false,
  });

  const setState = (changes) => setStateHelper((prevState) => ({ ...prevState, ...changes }));

  const getCustomerData = () => {
    setState({ isLoading: true });
    Api.get(`/user/safe-user/${match.params.userId}`).then(
      (data) => {
        setState({ customerData: data?.data, isLoading: false, isError: false });
      }
    ).catch(err => {

      console.log('err', err);
      setState({ isLoading: false, isError: true });
    });
  };
  useEffect(() => {
    getCustomerData();
  }, []);
  const { customerData, isLoading, isError } = state;
  if (isLoading) return <Loading />;
  if (isError) return (
    <div className="flex flex-col space-y-2 font-bold h-1/2 items-center justify-center">
      <p>Something Went Wrong</p>
      <p>Plsae Try Again</p>
      <Button variant="primary" onClick={getCustomerData}>Retry</Button>
    </div>
  )
  return (
    <div className="space-y-10">
      <div className="text-header-3">Customer Details</div>
      <PrimaryCard className="bg-oro-gradient w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5">
        <p className="text-body-large">{getCustomerDisplayId(customerData?.visitors?.primaryUser?.id)}</p>
        <p className="text-body-normal font-bold capitalize">signed up on 15/07/2021</p>
      </PrimaryCard>
      <div className="customers flex gap-5 flex-wrap">
        {customerData && Object.entries(customerData?.visitors).map(
          ([customerType, data], index) => <CustomerCard key={index} customerType={customerType} customerData={data} />,
        )}
      </div>
      <div className="visits flex gap-5 flex-wrap">
        {customerData?.visits?.rows?.map((visit, index) => (
          <VisitCard key={index} {...visit} />
        ))}
      </div>
      <div className="space-y-5">
        {customerData?.subscriptions?.map((subscriptions, index) => (
          <div key={index} className="space-y-10">
            <LockerBar {...subscriptions} />
            <div className="flex gap-5 flex-wrap">
              {subscriptions?.subscriptions?.map((subscription, index) => (
                <OroSafeCard key={index} {...subscription} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
