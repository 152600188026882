import AuthUtils from '../AuthUtils';
import ApiUtils from './index';

const baseConfig = (apiVersion = 'v1') => ({
  headers: {
    'content-type': 'application/json',
    'X-Renewal-Secret': 'register-master-auth',
    Authorization: `JWT ${AuthUtils.getToken()}`,
  },
  baseURL: `${process.env.REACT_APP_LOCAL_API}/api/${apiVersion}/`,
});

const Api = {
  get(path, config = {}) {
    return ApiUtils.get(path, {
      ...baseConfig(config?.apiVersion),
      ...config,
    });
  },
  post(path, payload, config = {}) {
    return ApiUtils.post(path, payload, {
      ...baseConfig(config?.apiVersion),
      ...config,
    });
  },
  put(path, payload, config = {}) {
    return ApiUtils.put(path, payload, {
      ...baseConfig(config?.apiVersion),
      ...config,
    });
  },
  getImages(data) {
    const urls = Array.isArray(data) ? data : [data];
    return this.post('/images', { urls }).then(
      (res) => (Array.isArray(data) ? res?.signedUrls : res?.signedUrls[0]),
    );
  },
  uploadImage(data) {
    let formData = new FormData();
    formData.append('image', data);
    return this.post('/images/upload-image', formData).then(
      res => res.url
    );
  },
};

export default Api;
