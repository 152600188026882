import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import Link from '../../components/Link';
import KeyValue from '../../components/Form/KeyValue';
import Modal from '../../components/Modal';
import Form from '../../components/Form/Form';
import Button from '../../components/Buttons/Button';
import LabelInput from '../../components/Form/LabelInput';
import PrimaryCard from '../../components/Cards/PrimaryCard';
import Api from '../../utils/ApiUtils/Api';
import Loading from '../../components/loading';
import Notification from '../../components/Notifications';
import Success from '../../components/Modals/Success';
import { capitalize } from 'lodash';
import permissions from '../../utils/permissionConfig';
import AccessCheck from '../../routes/accessCheck';

const SpaceDetails = ({ match }) => {
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const [state, setStateHelper] = useState({
    lockerTypes: [],
    count: 0,
    isLoading: true,
    isError: false,
    isSubmitting: false,
    createLockerTypeModal: false,
    spaceDetails: null,
    showLockerTypeCreationSuccess: false,

  });
  function setState(changes) {
    setStateHelper((prev) => ({
      ...prev,
      ...changes,
    }));
  }

  function onChange(type, e) {
    switch (type) {
      case "type":
        setState({ typeName: e.target.value });
        break;
      case "size":
        setState({ size: e.target.value });
        break;
      case "width":
        setState({ width: e });
        break;
      case "height":
        setState({ height: e });
        break;
      case "length":
        setState({ length: e });
        break;
      default:
    }
  }

  const getLockerTypes = (page = 1) => {
    setState({ isLoading: true });
    Api.get(`locker-type/space/${match?.params?.id}/detail-list?page=${page}`).then((data) => {
      setState({ lockerTypes: data.lockerTypes.rows, count: data.lockerTypes.count, isLoading: false });
    }).catch((error) => setState({ isLoading: false, isError: true }));
  }

  const getSpaceDetails = () => {
    setState({ isLoading: true })
    Api.get(`space-config/space/${match?.params.id}`).then(
      (data) => {
        setState({ isLoading: false, spaceDetails: data?.spaces })
      },
    ).catch(err => setState({ isLoading: false, isError: true }));
  }

  useEffect(() => {
    getSpaceDetails();
    getLockerTypes();
  }, []);

  const deactiveLocker = (id) => {
    setState({ isLoading: true })
    Api.put(`locker-type/${id}/deactivate`).then((data) => {
      setState({ isLoading: false })
      Notification.create('success', 'Locker is Deactivated successfully');
      setState({
        lockerTypes: state?.lockerTypes?.map((lockerType) => (lockerType?.id === parseInt(id, 10)
          ? { ...lockerType, status: 'DEACTIVATED' } : lockerType)), isLoading: false
      });
    }).catch((error) => setState({ isLoading: false, isError: true }));
  };

  const activateLocker = (id) => {
    setState({ isLoading: true })
    Api.put(`locker-type/${id}/activate`).then((data) => {
      Notification.create('success', 'Locker is Activated successfully');
      setState({
        lockerTypes: state?.lockerTypes?.map((lockerType) => (lockerType?.id === parseInt(id, 10)
          ? { ...lockerType, status: 'ACTIVE' } : lockerType)), isLoading: false
      });
    }).catch((error) => setState({ isLoading: false, isError: true }));
  };

  const columns = [
    {
      title: 'Locker Type',
      dataIndex: 'type',
      key: 'type',
      show: true,
      render: (text) => <p className="text-body-bold p-2">{text}</p>,
    },
    {
      title: 'Size Identifier',
      dataIndex: 'size',
      key: 'size',
      show: true,
      render: (text) => <p className="text-body-bold capitalize">{capitalize(text)}</p>,
    },
    {
      title: 'Dimensions',
      dataIndex: 'locker_dimensions',
      key: 'locker_dimensions',
      show: true,
      render: (text, data) => <p className="text-body-bold capitalize">{`${data?.width} X ${data?.height} X ${data?.length}`}</p>,
    },
    {
      title: 'Available Lockers',
      dataIndex: 'available_lockers',
      key: 'available_lockers',
      show: true,
      render: (text, data) => (<p className="text-body-bold capitalize">
        {data?.lockerTypeLockerActivity ? (data?.lockerTypeLockerActivity?.active_locker_count - data?.lockerTypeLockerActivity?.booked_locker_count) : 0}
      </p>)
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      show: true,
      render: (text, data) => {
        return (
          <>
            {data?.lockerTypeLockerActivity && (
              <AccessCheck
                requiredPermissions={permissions.spaces.table_row.table_row.view_lockers.permission}
                alternateChildren={<div></div>}
                looseCheck={false}
              >
                <Link variant="primary" className="text-xs font-bold"
                  to={`/spaces/${spaceDetails?.id}/locker-types/${data?.id}/details`}
                >
                  View Lockers
                </Link>
              </AccessCheck>
            )}
          </>
        )
      },
    },

    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      show: true,
      render: (text, data) => (
        <AccessCheck
          requiredPermissions={permissions.spaces.table_row.table_row.deactivate.permission}
          alternateChildren={<div></div>}
          looseCheck={false}
        >
          <Button variant="secondary" className="text-xs font-bold"
            onClick={() => (data?.status === 'DEACTIVATED' ? activateLocker(data?.id) : deactiveLocker(data?.id))}
          >
            {data?.status === 'DEACTIVATED' ? 'Activate' : 'Deactivate'}
          </Button>
        </AccessCheck>
      ),
    },
  ];


  const getLockerCount = (data) => {
    const res = { locker_count: 0, booked_locker_count: 0 };
    for (let i = 0; i < data?.length; i++) {
      res.locker_count += (data[i]?.locker_count ?? 0);
      res.booked_locker_count += (data[i]?.booked_locker_count ?? 0);
    }
    return res;
  };

  const addLockerType = () => {
    const { typeName, width, height, length, size } = state;
    const body = {
      type: typeName,
      size: size?.toUpperCase(),
      height: parseFloat(height),
      width: parseFloat(width),
      length: parseFloat(length),
    };
    setState({ isSubmitting: true })
    Api.post('locker-type', body).then(
      (data) => {
        getLockerTypes();
        setState({
          typeName: null, height: null, width: null, length: null, size: null, showLockerTypeCreationSuccess: true,
          createLockerTypeModal: false, isSubmitting: false
        });
        Notification.create('success', 'New Locker Type is Added Successfully')
      },
    ).catch(err => setState({ isSubmitting: false, isError: true }));
  };

  const handleTableChange = (pagination) => {
    getLockerTypes(pagination.current);
  }
  const { typeName, showLockerTypeCreationSuccess, width, height, length, size,
    lockerTypes, spaceDetails, isLoading, count, isError, isSubmitting, createLockerTypeModal } = state;


  return (
    <>
      <div className="space-y-10">
        <div className="flex gap-10 items-start flex-wrap">
          <p className="text-header-3">Space Details</p>
          <AccessCheck
            requiredPermissions={permissions.spaces.table_row.add_lockers_to_space.permission}
            alternateChildren={<div></div>}
            looseCheck={false}
          >
            <Link to={`/spaces/${spaceDetails?.id}/lockers/create`} variant="secondary"
              className="text-xs font-bold"
            >
              Add Lockers to Space
            </Link>
          </AccessCheck>
          <AccessCheck
            requiredPermissions={permissions.spaces.table_row.create_new_locker_type.permission}
            alternateChildren={<div></div>}
            looseCheck={false}
          >
            <Button variant="secondary" className="text-xs font-bold" onClick={() => setState({ createLockerTypeModal: true })}>
              Create New Locker Type
            </Button>
          </AccessCheck>
        </div>
        {spaceDetails && (
          <div className="flex gap-5 flex-wrap items-start">
            <PrimaryCard className="bg-oro-gradient w-3/4 md:w-1/3 xl:w-1/4 space-y-5">
              <div className="flex justify-between items-start">
                <div className="space-y-1">
                  <p className="text-body-large">
                    {spaceDetails?.space_number}
                  </p>
                  <p className="text-small">{spaceDetails?.name}</p>
                </div>
                <p className="bg-white p-2 rounded-lg text-xs font-bold">
                  {spaceDetails?.status}
                </p>
              </div>
              <div className="space-y-1">
                <p className="text-small uppercase">Available Lockers</p>
                <p className="text-header-3">
                  {getLockerCount(spaceDetails?.spaceLockerActivity).locker_count
                    - getLockerCount(spaceDetails?.spaceLockerActivity).booked_locker_count}

                </p>
              </div>
              <div className="text-small space-y-1">
                <p>
                  Total Lockers :
                  {' '}
                  {getLockerCount(spaceDetails?.spaceLockerActivity).locker_count}
                </p>
                <p>
                  Booked Lockers:
                  {' '}
                  {getLockerCount(spaceDetails?.spaceLockerActivity).booked_locker_count}
                </p>
              </div>
            </PrimaryCard>
            <div className="card w-3/4 md:w-1/3 xl:w-1/4 flex flex-col space-y-5 justify-between">
              <KeyValue label="Address" value={`${spaceDetails?.spaceAddress?.addr_line_1} 
            ${spaceDetails?.spaceAddress?.addr_line_2} 
            ${spaceDetails?.spaceAddress?.state} ${spaceDetails?.spaceAddress?.city}-${spaceDetails?.spaceAddress?.pincode}`}
              />
              <div className="flex space-x-5">
                <KeyValue label="lat" value={spaceDetails?.spaceAddress?.lat} />
                <KeyValue label="long" value={spaceDetails?.spaceAddress?.long} />
              </div>
            </div>
            <PrimaryCard className="card flex flex-col space-y-5 justify-between w-full md:w-1/2 xl:w-2/5">
              <p className="text-body-bold">Business Hours</p>
              <div className="flex gap-5 flex-wrap">
                {spaceDetails?.spaceBusicessHour?.map((day) => (day.open_time
                  ? <KeyValue label={days[day.day]} value={
                    `${day.open_time} to ${day.close_time}`
                  }
                  /> : <></>
                ))}
              </div>
            </PrimaryCard>
          </div>
        )}
        <Table loading={isLoading} className="w-3/4 mx-auto" scroll={{ x: 800 }} columns={columns} dataSource={lockerTypes}
          pagination={{ position: ['bottomCenter'], pageSize: 10, total: count }} onChange={handleTableChange}
        />
      </div>
      {createLockerTypeModal && (
        <Modal title="Create New Locker Type" onClose={() => setState({ createLockerTypeModal: false })}
          className="w-4/5 md:w-1/2 xl:w-2/5 p-10">
          <Form onSubmit={addLockerType} className="space-y-10">
            <LabelInput label="Type Name" type="text" className="text-base mb-8" placeholder="Enter Locker Type Name here"
              value={typeName} onChange={(e) => onChange('type', e)} required
            />
            <div className="flex flex-wrap">
              <div className="space-y-4 w-2/3">
                <p className="label">
                  SIZE (IN CM)
                </p>
                <div className="flex gap-2 items-center w-full">
                  <LabelInput type="numberText" placeholder="width" value={width}
                    rootClassName="w-1/4" inputClassName="p-2"
                    onChange={(e) => onChange('width', e)} required
                  />
                  <span className="label">X</span>
                  <LabelInput type="numberText" placeholder="height" value={height}
                    rootClassName="w-1/4" inputClassName="p-2"
                    onChange={(e) => onChange('height', e)} required
                  />
                  <span className="label">X</span>
                  <LabelInput type="numberText" placeholder="depth" value={length}
                    rootClassName="w-1/4" inputClassName="p-2"
                    onChange={(e) => onChange('length', e)} required
                  />
                </div>
              </div>
              <div className="w-1/3">
                <LabelInput label="Size Identifier" type="text" className="text-base" placeholder="Small/Medium/Large"
                  value={size} onChange={(e) => onChange('size', e)} required
                />
              </div>
            </div>
            <div className="flex gap-5">
              <Button variant="normal" className="w-full" onClick={() => setState({ createLockerTypeModal: false })}>Cancel</Button>
              <Button variant="cta" className="w-full" isLoading={isSubmitting} type="submit">Create New Locker Type</Button>
            </div>
          </Form>
        </Modal>
      )}
      {showLockerTypeCreationSuccess && (
        <Success body={
          <div className="text-center text-2xl font-bold w-3/4">
            New Locker Type added as per request
          </div>
        } onClose={() => setState({ showLockerTypeCreationSuccess: false })} />
      )}
    </>
  );
};
export default SpaceDetails;
