import AuthUtils from '../AuthUtils';
import ApiUtils from './index';

const baseURL = process.env.REACT_APP_AUTH_API;

const AuthApi = {
  getOtp(mobileNumber) {
    return ApiUtils.post('/api/v1/auth/sms-otp', {
      mobile_number: `+91${mobileNumber}`,
      role: "CUSTOMER",
    }, {
      baseURL,
    });
  },
  verifyOtp(otp, mobileNumber) {
    return ApiUtils.post('/api/v1/auth/verify-otp', {
      mobile_number: `+91${mobileNumber}`,
      otp,
      role: "CUSTOMER",
    }, {
      baseURL,
      headers: {
        "client-id": "customer-safe-app",
      },
    });
  },
  verifyToken() {
    return ApiUtils.post('/api/v1/auth/verify-token', {}, {
      baseURL,
      headers: {
        Authorization: `JWT ${AuthUtils.getToken()}`,
      },
    }).catch(async () => {
      await this.refreshToken();
      return ApiUtils.post('/api/v1/auth/verify-token', {}, {
        baseURL,
        headers: {
          Authorization: `JWT ${AuthUtils.getToken()}`,
        },
      });
    });
  },
  refreshToken() {
    return ApiUtils.post('/api/v1/auth/refresh-token', {}, {
      baseURL,
      headers: {
        'refresh-token': AuthUtils.getRefreshToken(),
      },
    }).then(({ user: { token } }) => AuthUtils.setToken(token));
  },
  googleVerifyToken(token) {
    return ApiUtils.post('/api/v1/auth/verify-google', {}, {
      baseURL,
      headers: {
        Authorization: `JWT ${token}`,
        "client-id": "customerapp",
      },
    });
  },
  logout() {
    return ApiUtils.post('/api/v1/auth/logout', {}, {
      baseURL,
      headers: {
        Authorization: `JWT ${AuthUtils.getToken()}`,
        "refresh-token": `${AuthUtils.getRefreshToken()}`,
      },
    });
  },
};

export default AuthApi;
