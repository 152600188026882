import { GetUserPermissions } from "../contexts/permission-context";
import { checkPermission } from "../utils";


export default function AccessCheck({
  children,
  requiredPermissions,
  alternateChildren,
  looseCheck
}) {
  const userPermissions = GetUserPermissions();
  const allowed = checkPermission(requiredPermissions, userPermissions.permissions, looseCheck);
  return allowed ? children : alternateChildren;
}
