import React from 'react';
import {
  Redirect, Route, BrowserRouter as Router, Switch,
} from 'react-router-dom';
import LandingPage from '../pages/LandingPage';

const UnAuthenticated = ({ setAuthenticated }) => (
  <Router>
    <Switch>
      <Route path="/"
        render={() => (
          <LandingPage setAuthenticated={setAuthenticated} />
        )}
      />
      <Route path="*" component={() => <Redirect to="/" />} />
    </Switch>
  </Router>
);

export default UnAuthenticated;
