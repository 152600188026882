import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TickIcon from '../../assets/tick.svg';
import RecordManualPayment from '../../components/Modals/recordManualPayment';
import Button from '../../components/Buttons/Button';
import PrimaryCard from '../../components/Cards/PrimaryCard';
import Api from '../../utils/ApiUtils/Api';
import Loading from '../../components/loading';
import Notification from '../../components/Notifications';

import OroSafePayment from '../../assets/SafePayment.svg';

export default function SubscriptionDetails(props) {
  const { current, setCurrent, state: stepperState } = props;
  const { id: visitId } = useParams();
  
  const [state, setStateHelper] = useState({
    subscriptionData: {},
    recordManualPaymentModal: false,
    paymentStatus: false,
    paymentData: {},
    subscriptionConfirmed: false,
    isLoading: true,
    isSubmitting: false,
    isError: false,
  });

  const setState = (changes) => setStateHelper((prevState) => ({ ...prevState, ...changes }));

  const getSubscriptionData = () => {
    if(stepperState.intermediate_status !== 'SAFE_CONFIRMED_SUBSCRIPTIONS' 
    && stepperState.intermediate_status !=='SAFE_PAYMENT_RECORDED'){
      setState({isLoading: true});
      Api.get(`safe/visit/${visitId}/subscription`).then(
        (data) => {
          setState({subscriptionData: data?.data, isLoading: false});
        },
      );
    }
    else if(stepperState.intermediate_status === 'SAFE_CONFIRMED_SUBSCRIPTIONS'){
      setState({subscriptionConfirmed: true});
    }
    else if(stepperState?.intermediate_status==='SAFE_PAYMENT_RECORDED') 
      setState({paymentStatus: true, subscriptionConfirmed: true});
    setState({isLoading: false});
  }
  useEffect(() => {
    getSubscriptionData();
  }, []);

  const checkPendingStatus = () => {
    setState({isSubmitting: true});
    Api.get(`payment/${visitId}/order-details`).then(
      (data) => {
        setState({paymentStatus: data?.order?.status === 'paid', isSubmitting: false});
  }).catch(err => setState({ isSubmitting: false }));
  }

  const confirmSubscription = () => {
    setState({isSubmitting: true});
    Api.post(`safe/visit/${visitId}/confirm-subscription`, {}).then(
      (data) => {
        Notification.create('success', 'Confirmed Subscription Successfully');
        setState({isSubmitting: false, subscriptionConfirmed: true})
      },
    ).catch(err => setState({isSubmitting: false, isError: true}));
  };

  const {isLoading, isError, subscriptionConfirmed, subscriptionData, paymentData, paymentStatus,
    recordManualPaymentModal, isSubmitting} = state;
  if (isLoading) return <Loading />;
  if(isError) return (
    <div className="flex flex-col space-y-2 font-bold h-1/2 items-center justify-center">
      <p>Something Went Wrong</p>
      <p>Please Try Again</p>
      <Button variant="primary" onClick={getSubscriptionData}>Retry</Button>
    </div>
  )

  return (
    <>
      {!subscriptionConfirmed && stepperState?.intermediate_status !== 'SAFE_CONFIRMED_SUBSCRIPTIONS' ? (
        <>
          <div className="space-y-5 w-full xl:w-5/6  mx-auto">
            <div className="bg-medium-yellow w-full shadow-lg lg:text-base text-xs font-bold flex gap-3 xl:gap-5 ">
              <div className="flex gap-5 w-3/4 text-left p-5">
                <p className="w-1/2">Locker Type</p>
                <p className="w-1/2">Locker ID</p>
                <p className="w-1/2">Term</p>
                <p className="w-1/2">Subscription Date</p>
                <p className="w-1/2">Exipry Date</p>
              </div>
              <div className="flex gap-5 w-1/4  text-center py-5">
                <p className="w-1/2">Subscription Cost</p>
                <p className="w-1/2">Security Deposit</p>
              </div>
            </div>
            <div className="flex flex-col w-full gap-5">
              {subscriptionData?.lockers?.map((locker, index) => (
                <div key={index} className="flex gap-5 w-full md:text-base text-xs font-bold">
                  <div className="p-5 shadow-md bg-white flex gap-3 lg:gap-5 w-3/4 text-left ">
                    <p className="w-1/2">{locker?.locker_type_name}</p>
                    <p className="w-1/2">{locker?.locker_number}</p>
                    <p className="w-1/2">
                      {locker?.tenure}
                      {' '}
                      months
                    </p>
                    <p className="w-1/2">{moment(locker.subscription_date).format('DD/MM/YYYY')}</p>
                    <p className="w-1/2">{moment(locker.expiry_date).format('DD/MM/YYYY')}</p>
                  </div>
                  <div className="flex gap-3 w-1/4 text-center">
                    <p className="w-full p-5 bg-white shadow-md">
                      ₹
                      {locker?.cost}
                    </p>
                    <p className="w-full p-5 bg-white shadow-md">
                      ₹
                      {locker?.security_deposit}
                    </p>
                  </div>
                </div>
              ))}
              <div className="flex gap-5 w-full text-base font-bold">
                <p className="w-3/4 bg-white p-5 shadow-md text-right">Oro Safe Subscription Amount</p>
                <p className="w-1/4 p-5 bg-white shadow-md text-center text-2xl">
                  ₹
                  {subscriptionData?.lockers?.reduce((acc, locker) => acc + locker?.cost ?? 0, 0)}
                </p>
              </div>
              <div className="flex gap-5 w-full text-base font-bold">
                <p className="w-3/4 bg-white p-5 shadow-md text-right">Total Security Deposit</p>
                <p className="w-1/4 p-5 bg-white shadow-md text-center text-2xl">
                  ₹
                  {subscriptionData?.lockers?.reduce((acc, locker) => acc + locker?.security_deposit ?? 0, 0)}
                </p>
              </div>
              <div className="flex gap-5 w-full text-base font-bold">
                <p className="w-3/4 bg-white p-5 shadow-md text-right">GST (18%) - Applicable only on Oro Safe Price</p>
                <p className="w-1/4 p-5 bg-white shadow-md text-center text-2xl">
                  ₹
                  {subscriptionData?.totalGst}
                </p>
              </div>
              <div className="flex gap-5 w-full text-base font-bold">
                <p className="w-3/4 bg-medium-yellow p-5 shadow-md text-2xl text-right"> Total Amount to be paid</p>
                <p className="w-1/4 p-5 bg-medium-yellow shadow-md text-center text-3xl">
                  ₹
                  {subscriptionData?.cartAmount}
                </p>
              </div>
            </div>
          </div>
          <div className="flex space-x-5 mt-10 justify-center">
            {/* <Button type="button" variant="normal" className="w-1/2 bg-white border border-[#CECECE]"
              onClick={() => setCurrent(current - 1)}
            >
              Back To Add Lockers
            </Button> */}
            <Button variant="cta" type="submit" className="w-1/2" isLoading={isSubmitting} onClick={confirmSubscription}>
              Confirm Subscription
            </Button>
          </div>
        </>
      ) : (
        <PrimaryCard className="flex flex-col items-center justify-center space-y-8 mx-auto w-3/4">
          <img src={OroSafePayment} alt="oro-safe" />
          {!paymentStatus
            ? (
              <>
                <p className="text-body-large">Payment Pending</p>
                <div className="w-1/2 text-center">
                  <p className="text-body-normal">
                    If customer is paying online, please wait until customer completes
                    the payment and the status will automatically change to completed.
                  </p>
                </div>
              </>
            )
            : (
              <>
                <div className="flex space-x-2">
                  <img src={TickIcon} alt="completed" />
                  <p className="text-body-large flex space-x-4">
                    Payment Completed
                  </p>
                </div>
                <div className="w-3/4 lg:w-1/2 text-center">
                  <p className="text-body-normal">
                    Payment has been completed by the customer. Please proceed with the next steps
                    to complete the visit.
                  </p>
                </div>
              </>
            )}
          {!paymentStatus ? (
            <div>
              <Button className="w-full mx-auto mb-4 text-md font-bold" variant="normal" isLoading={isSubmitting}
                onClick={checkPendingStatus}>
              Check Online Payment Status
              </Button>
              <p className="text-xs font-semibold mb-8">Click here to check if the payment has been made by the customer</p>
              <Button className="w-full mx-auto uppercase text-xsm" variant="normal" 
                onClick={() => setState({recordManualPaymentModal: true})}
              > Record manual payment
              </Button>
            </div>
          ):(
            <div className="w-full lg:w-3/4 mx-auto flex md:flex-row flex-col gap-5">
              <Button type="button" className="w-full md:w-1/2 mx-auto" variant="cta"
                onClick={()=> setCurrent(current+1)}
              >
              Proceed
              </Button>
            </div>
          )}
        </PrimaryCard>
      )}
      {recordManualPaymentModal && (
        <RecordManualPayment
          visitId={visitId}
          setVisible={(value) => setState({recordManualPaymentModal: value})}
          setPaymentStatus={(value) => setState({paymentStatus: value})}
        />
      )}
    </>
  );
}
