/* eslint-disable react/jsx-handler-names */
import React, { useState, useCallback } from "react";
import moment from "moment";
import LabelInput from "../../../../components/Form/LabelInput";
import { DatePicker, TimePicker } from "antd";
import { indianStates, getCityAndStateDataFromPincode } from "../../../../utils";
import PrimaryCard from "../../../../components/Cards/PrimaryCard";
import { Link, useHistory } from "react-router-dom";
import Button from "../../../../components/Buttons/Button";
import { DOORSTEP_CUSTOMER_TYPES } from "../../../../constants/DoorstepVisitsRadioButtonOptions";
import Form from "../../../../components/Form/Form";
import Api from "../../../../utils/ApiUtils/Api";
import Notification from "../../../../components/Notifications";
import { disabeleMinutes, disabledHours } from "./utils";

export default function ManageDoorstepLockerForm({ visitSubType }) {
  const history = useHistory();
  const [state, setStateHelper] = useState({
    isLoading: true,
    isError: false,
    isSubmitting: false,
    isSearching: false,
    formError: ""
  });

  const setState = (changes) => {
    setStateHelper((prevState) => ({
      ...prevState,
      ...changes
    }));
  };

  const autoFillCityAndStateUsingPincode = useCallback(async (pincode) => {
    const { cityName, stateName } = await getCityAndStateDataFromPincode(pincode);

    if (cityName) setState({ city: cityName });
    if (stateName) setState({ selectedState: stateName });
  }, []);

  const onManageDoorstepVisit = () => {
    const {
      firstName,
      lastName,
      mobile,
      addressLine1,
      addressLine2,
      city,
      selectedState,
      pincode,
      latitude,
      longitude,
      date,
      time
    } = state;
    const visitDate = moment(date).format("DD/MM/YYYY");
    const visitTime = moment(time).format("HH:mm");
    const visitDateTime = moment(
      `${visitDate} ${visitTime}`,
      "DD/MM/YYYY HH:mm"
    );
    setState({ isSubmitting: true, isError: false });
    Api.post("/visit/doorstep-visit", {
      visitTime: visitDateTime,
      firstName,
      visitType: DOORSTEP_CUSTOMER_TYPES[visitSubType].value,
      lastName,
      mobileNumber: `+91${mobile}`,
      address: {
        city: city,
        state: selectedState,
        lat: latitude,
        long: longitude,
        pincode: pincode,
        addr_line_1: addressLine1,
        addr_line_2: addressLine2
      }
    })
      .then(() => {
        setState({ isSubmitting: false });
        Notification.create("success", "Visit Created Successfully");
        history.push("/visits");
      })
      .catch(() => {
        setState({ isSubmitting: false, isError: true });
      });
  };

  const validation = () => {
    if (!state.date) {
      setState({ formError: "Please Select Date" });
      return false;
    }
    if (!state.time) {
      setState({ formError: "Please Select Time" });
      return false;
    }
    setState({ formError: "" });
    return true;
  };

  const searchUsingMobile = () => {
    setState({ isSearching: true, isError: false });
    Api.get(`user/safe-user?mobile_number=${state?.mobile}`)
      .then((data) => {
        setState({
          firstName: data?.safeUser?.primaryUser?.first_name,
          lastName: data?.safeUser?.primaryUser?.last_name,
          customerName: data?.safeUser?.primaryUser?.name,
          addressLine1: data?.safeUser?.primaryUser?.Customer?.address?.[0]?.addr_line_1,
          addressLine2: data?.safeUser?.primaryUser?.Customer?.address?.[0]?.addr_line_2,
          pincode: data?.safeUser?.primaryUser?.Customer?.address?.[0]?.pincode,
          latitude: data?.safeUser?.primaryUser?.Customer?.address?.[0]?.lat,
          longitude: data?.safeUser?.primaryUser?.Customer?.address?.[0]?.long,
          isSearching: false
        });
        autoFillCityAndStateUsingPincode(data?.safeUser?.primaryUser?.Customer?.address?.[0]?.pincode);
      })
      .catch(() => {
        setState({
          firstName: '',
          lastName: '',
          customerName: '',
          addressLine1: '',
          addressLine2: '',
          pincode: '',
          latitude: '',
          longitude: '',
          city: '',
          selectedState: '',
          isSearching: false,
          isError: true
        });
      });
  };


  const onChange = (type, e) => {
    switch (type) {
    case "mobile":
      setState({ mobile: e });
      break;
    case "addr_line_1":
      setState({ addressLine1: e.target.value });
      break;
    case "addr_line_2":
      setState({ addressLine2: e.target.value });
      break;
    case "state":
      setState({ selectedState: e.target.value });
      break;
    case "city":
      setState({ city: e.target.value });
      break;
    case "pincode":
      setState({ pincode: e });
      break;
    case "date":
      setState({ date: e });
      break;
    case "time":
      setState({ time: e });
      break;
    case "latitude":
      setState({ latitude: e });
      break;
    case "longitude":
      setState({ longitude: e });
      break;
    default:
    }
  };

  const {
    customerName,
    mobile,
    addressLine1,
    addressLine2,
    city,
    selectedState,
    pincode,
    latitude,
    longitude,
    date,
    time,
    isSubmitting,
    formError,
    isSearching
  } = state;

  return (
    <PrimaryCard className="p-10 w-full md:w-3/4 xl:w-3/5">
      <Form customValidation={validation} onSubmit={onManageDoorstepVisit}>
        {/* // VISIT SUB TYPE INPUT */}
        <div className="pt-6">
          <LabelInput
            label="visit sub-type"
            type="text"
            placeholder="Enter Customer visit sub-type"
            rootClassName="w-1/3"
            value={DOORSTEP_CUSTOMER_TYPES[visitSubType].label}
            disabled
          />
        </div>
        {/* // CUSTOMER MOBILE NUMBER AND NAME */}{" "}
        <div className="flex space-x-5 items-end pt-6">
          <LabelInput
            label="Mobile Number"
            type="numberText"
            placeholder="Search using mobile number"
            rootClassName="w-3/4 lg:w-1/2"
            value={mobile}
            onChange={(e) => onChange("mobile", e)}
            required
            maxLength={10}
          />
          <div style={{ marginBottom: formError ? "30px" : "5px" }}>
            <Button
              variant="primary"
              className="mb-0"
              isLoading={isSearching}
              onClick={searchUsingMobile}
            >
              Search
            </Button>
          </div>
        </div>
        <div className="flex gap-6 flex-col md:flex-row pt-6">
          <LabelInput
            label="customer name"
            type="text"
            placeholder="Customer Name Here"
            rootClassName="w-1/2"
            value={customerName}
            disabled
            required
          />
        </div>
        {/* // CUSTOMER ADDRESS */}
        <div className="flex flex-col space-y-4 pt-6">
          <p className="label">Customer Address</p>
          <LabelInput
            type="text"
            placeholder="Address line 1"
            value={addressLine1}
            required
            onChange={(e) => onChange("addr_line_1", e)}
          />
          <LabelInput
            type="text"
            placeholder="Address line 2"
            value={addressLine2}
            onChange={(e) => onChange("addr_line_2", e)}
          />
          <div className="flex gap-5 flex-wrap">
            <LabelInput
              type="numberText"
              placeholder="Pincode"
              rootClassName="w-1/4"
              value={pincode}
              required
              onChange={(e) => {
                if (e?.length === 6) autoFillCityAndStateUsingPincode(e);
                onChange("pincode", e);
              }}
              maxLength={6}
            />
            <LabelInput
              type="text"
              placeholder="City"
              rootClassName="w-1/4"
              value={city}
              required
              disabled
              onChange={(e) => onChange("city", e)}
            />
            <LabelInput
              type="select"
              placeholder="State"
              rootClassName="w-1/4"
              value={selectedState}
              options={indianStates}
              required
              disabled
              onChange={(e) => onChange("state", e)}
            />
          </div>
        </div>
        {/* // LATITUDE AND LONGTITUDE */}
        <div className="flex gap-5 flex-wrap pt-6">
          <LabelInput
            label="latitude"
            maxLength={8}
            type="numberText"
            placeholder="Enter Lat"
            value={latitude}
            onChange={(e) => onChange("latitude", e)}
            required
          />
          <LabelInput
            label="longitude"
            maxLength={8}
            type="numberText"
            placeholder="Enter Long"
            value={longitude}
            onChange={(e) => onChange("longitude", e)}
            required
          />
        </div>
        {/* DATE AND TIME PICKER  */}
        <div className="space-y-4 pt-6">
          <p className="label">VISIT DATE &amp; TIME</p>
          <div>
            <div className="flex gap-6 flex-wrap">
              <DatePicker
                format="DD-MM-YYYY"
                disabledDate={(date) => date <= moment().subtract(1, "day")}
                className={`rounded-lg p-3 border ${
                  formError ? "border-error-red" : ""
                }`}
                value={date}
                onChange={(value) => (
                  onChange("time", null), onChange("date", value)
                )}
              />
              <TimePicker
                disabled={!date}
                disabledHours={disabledHours}
                disabledMinutes={() => disabeleMinutes(state)}
                className={`rounded-lg p-3 border ${
                  formError ? "border-error-red" : ""
                }`}
                format="HH:mm"
                minuteStep={5}
                value={time}
                onChange={(value) => onChange("time", value)}
              />
            </div>
            <p className="text-error-red mt-2">{formError}</p>
          </div>
        </div>
        <div className="flex justify-center items-center gap-5 flex-col-reverse md:flex-row pt-10">
          <Link
            variant="normal"
            className="w-3/4 md:w-1/2 text-center bg-white px-5 border border-[#454545] py-3.5 rounded-full"
            to="/visits"
          >
            Cancel
          </Link>
          <Button
            type="submit"
            isLoading={isSubmitting}
            className="w-3/4 md:w-1/2"
            variant="cta"
          >
            Create New Visit
          </Button>
        </div>
      </Form>
    </PrimaryCard>
  );
}
